import React from 'react'
import PropTypes from 'prop-types'



const Services = ({ services }) => (
  <div>
    {services.map((service) => (
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="columns">
            <div className="column is-7">
              <h3 className="has-text-weight-semibold is-size-3">
                  {service.title}
              </h3>
              <p>{service.description}</p>
              {service.lists && service.lists.map((list) => (
                 <li>{list}</li>
              ))}
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
)

Services.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      lists: PropTypes.array
    })
  ),
}

export default Services
